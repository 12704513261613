import React from 'react'

import { Link, navigate } from "gatsby"

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Stack, Button, Typography } from '@mui/material';

import { FaFile, FaDownload } from "react-icons/fa";

import Layout from '../components/Layout'
import AppDetail from '../components/AppDetail'

import { listTesting } from "../data"

import translateManager from "../translations/translateManager"

export default function testing() {

    const dlFile = (filename) => {
        const link = document.createElement('a');
        let filepath = process.env.APK_DOWNLOAD_PATH_PREFIX + "/testing/" + filename;
        console.log(filepath);
        link.href = filepath;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

    

    return (
        <Layout>
            <Typography style={{margin: "1em 0 2em 0"}} align="center" variant="string" component="div">
            {translateManager.getText("TESTING_INFO_TEXT")}
            </Typography>

             
            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{translateManager.getText("TABLE_HEADER_APP_TYPE")}</TableCell>
                            <TableCell>{translateManager.getText("TABLE_HEADER_VERSION")}</TableCell>
                            <TableCell>{translateManager.getText("TABLE_HEADER_SERVER")}</TableCell>
                            <TableCell align="center">{translateManager.getText("TABLE_HEADER_DOWNLOAD")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {listTesting.map((item) => {             
                        return (

                            <TableRow
                                key={item.version}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                <TableCell component="th" scope="row">
                                    {item.appName}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {item.version}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {item.server}
                                </TableCell>
                                <TableCell align="center" style={{cursor: "pointer"}}><FaDownload onClick={() => dlFile(item.filename)} /></TableCell>
                            </TableRow>
                        )}
                    )}
                    </TableBody>
                </Table>
            </TableContainer>
            
        </Layout>
    )
}
